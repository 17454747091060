<template>
  <v-container style="max-width:1200px;">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="8"
        >

        <!-- Thông tin cơ bản -->
        <v-card flat style="border-radius:8px;border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 pl-0"
            style="font-size:24px;color:#AD1457;border-bottom:1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                @click="goBack" class="mr-2">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
              {{property && property._id?'Thông Tin Thiết Bị':'Tạo Thiết Bị Mới'}}
            <v-spacer />
            <v-btn
              class="my-1"
              style="min-width:120px;"
              color="#AD1457" dark
              :loading="saveLoading"
              @click.stop="callSaveProperty">
              <v-icon class="mr-2">
                mdi-content-save
              </v-icon>
              Lưu
            </v-btn>
          </v-card-title>

          <v-card-text 
            v-if="displayDeptAfterFilter"
            class="pa-4"
            :class="{'px-2': $vuetify.breakpoint.smAndDown}">
            <div
              style="width:100%;font-size:15px;"
            >

              <!-- Room name -->
              <div style="mt-2">
                Tên Thiết Bị *:
              </div>
              <div>
                <v-text-field
                  flat
                  dense
                  v-model="property.name"
                  :rules="[rules.required]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <!-- Brandname And Serial -->
              <div>
                <div style="display:inline-block;width:49%;">
                  <div class="mt-2">
                    Model:
                  </div>
                  <div>
                    <v-text-field
                      flat
                      dense
                      v-model="property.props.product_brandname"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
                <div style="display:inline-block;float:right;width:49%;">
                  <div class="mt-2">
                    Số Serial:
                  </div>
                  <div v-if="property.props">
                    <v-text-field
                      flat
                      dense
                      v-model="property.props.product_serial"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <!-- QR And Owner -->
              <div>
                <div style="display:inline-block;width:49%;">
                  <div class="mt-2">
                    Mã QRCode:
                  </div>
                  <div>
                    <v-text-field
                      flat
                      dense
                      v-model="property.qr_code"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
                <div style="display:inline-block;float:right;width:49%;">
                  <div class="mt-2">
                    Chủ Thiết Bị:
                  </div>
                  <div v-if="property.props">
                    <v-text-field
                      flat
                      dense
                      v-model="property.owner"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
              </div>

               <!-- Status and Makho -->
              <div>
                <div style="display:inline-block;width:49%;">
                  <div class="mt-2">
                    Trạng thái:
                  </div>
                  <div>
                    <v-text-field
                      flat
                      dense
                      v-model="property.status"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
                <div style="display:inline-block;float:right;width:49%;">
                  <div class="mt-2">
                    Mã kho:
                  </div>
                  <div v-if="property.props">
                    <v-text-field
                      flat
                      dense
                      v-model="property.props.makho"
                      :rules="[]"
                      color="teal"
                      :clearable = "$vuetify.breakpoint.smAndDown"
                      outlined filled
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <!-- image -->
              <div class="py-1 mt-2">
                <!-- upload image -->
                <div>
                  <input
                    type="file"
                    style="display: none"
                    ref="imageInput"
                    accept="*/*"
                    @change="handleFileUpload"
                  />

                  <v-btn
                    @click.stop="startPickImage"
                    class="ml-2"
                    color="teal"
                    outlined
                    style="min-width:20px;width:46px;"
                  >
                    <v-icon>
                      add_photo_alternate
                    </v-icon>
                  </v-btn>
                </div>
                <!-- list of image -->
                <div class="py-2">
                  <template v-for="(img, imgIdx) in property.images">
                    <div
                      class="pa-1 mr-2 mb-2"
                      :key="'img' + imgIdx"
                      style="vertical-align: top;position:relative;display: inline-block;min-height: 80px;max-height: 80px;min-width: 130px;max-width: 130px;border:1px solid #DDDDDD;"
                    >
                      <div
                        @click.stop="removeImage(imgIdx)"
                        style="position:absolute;top:-10px;right:-5px;z-index:2;cursor:pointer;"
                      >
                        <v-icon color="red" style="font-size:20px;"
                          >mdi-close-circle-outline</v-icon
                        >
                      </div>

                      <div
                        style="width:100%;height:70px;z-index:1; overflow-y:hidden;cursor:pointer;"
                        @click.stop="showImageFullScreen(img)"
                      >
                        <img
                          :src="img"
                          style="object-fit:contain;width:100%;height:100%;"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="mt-2">
                Mô tả:
              </div>
              <div class="description">
                <v-textarea
                  flat
                  dense
                  v-model="property.description"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined
                  filled auto-grow
                  hide-details
                ></v-textarea>
              </div>
            </div>
          </v-card-text>
          <!--
          <v-card-actions 
            v-if="property"
            class="pr-4">
            <v-spacer/>
          </v-card-actions>
          -->
        </v-card>

        <!-- lịch sử cho mượn -->
        <v-card v-if="property && property._id"
          class="mt-6"
          flat style="border-radius:8px;border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 pl-0"
            style="font-size:24px;color:#AD1457;border-bottom:1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                class="mr-2">
                <v-icon>mdi-history</v-icon>
            </v-btn>
            Lịch sử cho mượn
            <v-spacer />
          </v-card-title>

          <v-card-text
            class="pa-4"
            :class="{'px-2': $vuetify.breakpoint.smAndDown}">
              <equipment-history-lend :propertyID="property._id"/>
          </v-card-text>
        </v-card>

        <!-- Lịch sử hao mòn  -->
        <v-card v-if="property && property._id"
          class="mt-6"
          flat style="border-radius:8px;border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 pl-0"
            style="font-size:24px;color:#AD1457;border-bottom:1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                class="mr-2">
                <v-icon>mdi-history</v-icon>
            </v-btn>
            Lịch sử hao mòn
            <v-spacer />
          </v-card-title>

          <v-card-text
            class="pa-4"
            :class="{'px-2': $vuetify.breakpoint.smAndDown}">
            <div
              style="width:100%;font-size:15px;"
            >
              <v-btn
                v-if="!showAddDamageEvent"
                class="my-1"
                style="min-width:160px;"
                color="#AD1457" dark
                @click.stop="showAddDamageEvent=true">
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                Thêm lịch sử
              </v-btn>
              <div-add-damage
                :show="showAddDamageEvent"
                @close-dialog="showAddDamageEvent=false"
                @add-event="addNewEvent"
              />
              <equipment-histoty-damage 
                v-if="!showAddDamageEvent"
                :history="property.props.history" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <show-full-image
      :show="showFullImage"
      :image="imgFullScreen"
      @close-dialog-show-fullimage="showFullImage = false"
    ></show-full-image>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  watch: {
    '$route.params.props_id': async function () {
      this.onPageLoad()
    },
  },
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayDeptAfterFilter() {
      let result = null
      if (!this.property) return null
      result = JSON.parse(JSON.stringify(this.property))
      return result
    },
  },
  data() {
    return {
      roles: ['equipments-manager', 'admin'],
      property: null,
      saveLoading: false,
      dataBeforeSave: null,
      showFullImage: false,
      showAddDamageEvent: false,
      imgFullScreen: "",
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },
  methods: {
    goBack () {
      // this.$router.go(-1)
      this.$router.push({path: '/equipments'})
    },
    showImageFullScreen(link) {
      this.showFullImage = true;
      this.imgFullScreen = link;
    },
    removeImage(p) {
      this.property.images.splice(p, 1);
    },
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            console.log("Upload image:", res.data);
            self.property.images.push(res.data.content);
          })
          .catch(function(err) {
            console.log("Err upload:", err);
          });
      }
    },
    createPropertyCode (name) {
      return name.normalize("NFD")
                                 .replace(/[\u0300-\u036f]/g, "")
                                 .replace(/đ/g, "d")
                                 .replace(/Đ/g, "D")
                                 .replace(/\s+/g, '-')
                                 .toUpperCase() + "-"+ new Date().getTime()
    },
    callCreateProperty () {
      let self = this
      this.property.code = this.createPropertyCode(this.property.name)
      this.property.type = 'thiet-bi'
      this.saveLoading = true

      this.axios
        .post(this.$root.apiAssetMana + "/properties",
              self.property,
              { headers: { "x-auth": self.token } })
        .then((res) => {
          if (res.data.status === "OK") {
            alert("Tạo thiết bị " + self.property.name + " thành công!");
            self.$router.push({path: '/equipts-detail/' + res.data.content._id})
          } else {
            alert("Lỗi: " + res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi: " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    callSaveProperty() {
      if (!this.property) return

      // if (!this.property.code || this.property.code.length <= 0) {
      //   alert("Mã phòng ban không được để trống!");
      //   return;
      // }
      if (!this.property.name || this.property.name.length <= 0) {
        alert("Tên phòng ban không được để trống!");
        return;
      }

      if(!this.property._id) {
        this.callCreateProperty()
        return
      }
      this.saveLoading = true

      let self = this
      this.axios
        .post(this.$root.apiAssetMana + "/properties", self.property, {
          headers: {
            "x-auth": this.token,
          },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            self.property = response.data.content;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    fetchProperty() {
      let self = this;
      self.axios
        .get(
          self.$root.apiAssetMana +
            "/properties/" +
            this.$route.params.props_id,
          { headers: { "x-auth": this.token }}
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.property = res.data.content;
            if (!self.property.props) {
              self.property.props = {
                product_serial: ''
              }
            }
            console.log(self.property)
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewEvent(event) {
      this.showAddDamageEvent = false
      if (!this.property.props.history) {
        this.property.props.history = []
      }
      this.property.props.history.unshift(event)
      this.callSaveProperty()
    },
    onPageLoad () {
      if(this.$route.params.props_id){
        if(this.$route.params.props_id=='0') {
          this.property = {
            name: '',
            owner: '',
            description: '',
            images: [],
            code: '',
            type: 'thiet-bi',
            props: {
              product_serial: '',
              product_brandname: '',
              history: []
            },
            enable: true
          } 
        } else {
          this.fetchProperty()
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.onPageLoad()
    })
  },
};
</script>

<style>
  .description textarea {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    line-height: 1.35;
    font-size: 14px;
  }
</style>
